import React from "react"
import { Link, graphql } from "gatsby"
//import parse from "html-react-parser"

//import Layout from "../components/layout"
import Seo from "../components/SEO"
import ArtigoItem from "../components/ArtigoItem"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, totalPages, currentPage },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </>
    )
  }

  return (
    <>
      <Seo title="All posts" />
      <div className="artigos-head">
        <div className="container">
          <span className="title">Artigos</span>
        </div>
      </div>
      <section className={"lista-artigos "}>
        <div className="container">
          <div className="row">
            {/* <div className={"col-lg-3 left-side "}></div> */}
            <div className="col-lg-12 lista-container">
              <div className="lista">
                {posts.map((artigo, key) => (
                  <ArtigoItem artigo={artigo} key={key} />
                ))}
              </div>
              {totalPages > 1 && (
                <div className="pagination">
                  <Link
                    className="main-link"
                    to={previousPagePath || "/"}
                    disabled={!previousPagePath}
                  >
                    {"< Página anterior"}
                  </Link>
                  <div className="pages">
                    {[...Array(totalPages).keys()].map((o, k) => {
                      const page = o + 1
                      const url = "/artigos" + (page === 1 ? "" : "/" + page)
                      const isCurrent = page === currentPage

                      return (
                        <Link
                          className="main-link"
                          key={k}
                          to={url}
                          disabled={isCurrent}
                        >
                          {page}
                        </Link>
                      )
                    })}
                  </div>
                  <Link
                    className="main-link"
                    to={nextPagePath || "/"}
                    disabled={!nextPagePath}
                  >
                    {"Página seguinte >"}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date
        title
        excerpt
        slug
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
`
